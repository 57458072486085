<template>
    <div class="xml">
        <div class="xml__content">
            <div class="xml__content__texts">
                <h1>Plik XML</h1>
            </div>
        </div>
            <div class="emptyState">
        <transition name="fade">
                <div class="emptyState__header">
                    {{ !isDownloaded ? "W ciągu kilku sekund powinno rozpocząć się pobieranie pliku." : "Dziękujemy za pobranie pliku!" }}
                </div>
        </transition>
        <transition name="fade">
                <div class="emptyState__subtext">
                    {{ !isDownloaded ? "" : "Jeśli pobieranie nie rozpoczęło się automatycznie, proszę kliknąć przycisk poniżej." }}
                </div>
                </transition>
        <transition name="fade">
                <div class="emptyState">
                    <div class="xml__content__input__getQuery">
                        <button ref="xmlButton" @click="xmlCreate()" :disabled="!isDownloaded" download>Pobierz</button>
                    </div>
                </div>
        </transition>
            <div class="emptyState__image">
                <img v-if="!isDownloaded" src="../assets/export_min.png" alt="Najtansze komputery poleasingowe ze sklepu komputerowego. Znajdziesz równiez laptopy uzywane oraz tanie monitory full hd z matryca ips. W naszym asortymencie posiadamy szeroki wybor czesci komputerowych."> 
            </div>
            <div class="emptyState__imageSecond">
                <img v-if="isDownloaded" src="../assets/thankyou_min.png" alt="Najtansze komputery poleasingowe ze sklepu komputerowego. Znajdziesz równiez laptopy uzywane oraz tanie monitory full hd z matryca ips. W naszym asortymencie posiadamy szeroki wybor czesci komputerowych."> 
            </div>
            </div>
        <navbar/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import axios from 'axios';
    import { db } from '../configs/firebase';
    import { storage } from '../configs/firebase';
    import { firebase } from '../configs/firebase';
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";

    export default {
        data() {
            return {
                products: [],
                componentInitLoad: false,
                beforeLoad: false,
                isDownloaded: false,
                productsUrls: [],
                publicLinks: [],
                urlRefs: [],
                urls: [],
                publicPreparedUrls: [],
            }
        },
        methods: {
            async getItems() {
                this.beforeLoad = true;
                this.componentInitLoad = true;
                this.products = [];
                this.dbListener = await db.collection("Products").orderBy("uniqueID").get()
                this.dbListener.forEach(doc => {
                    this.products.push(doc.data());
                    this.lastItemId = doc.data().uniqueID;
                });

                this.urlRefs = [];
                this.urls = [];

                for(let i=0;i<this.products.length; i++)
                {
                    if(this.products[i].baselinkerPreparedImages.length > 0)
                    {
                        let baselinkerImages = {}
                        baselinkerImages.id = this.products[i].uniqueID
                        this.urls.push(baselinkerImages)
                        let baselinkerUrls = [];
                        for(let y=0;y<this.products[i].baselinkerPreparedImages.length;y++)
                        {
                            let url = this.products[i].baselinkerPreparedImages[y]
                            let image = url.slice(4, url.length)
                            baselinkerUrls.push(image)
                        }
                        baselinkerImages.refs = baselinkerUrls
                    }
                    if(this.products[i].publicPreparedImages === undefined || this.products[i].publicPreparedImages.length === 0)
                    {
                        if(this.products[i].baselinkerPreparedImages.length === 0)
                        {
                        let imageGallery = {}
                        imageGallery.id = this.products[i].uniqueID
                        this.urlRefs.push(imageGallery)
                        let imageGalleryUrls = [];
                        imageGalleryUrls.push(await firebase.storage().refFromURL(this.products[i].mainImage.url).name)
                        if(this.products[i].imageGallery.length > 0)
                        {
                            for(let y=0; y<this.products[i].imageGallery.length;y++)
                            {
                                imageGalleryUrls.push(await firebase.storage().refFromURL(this.products[i].imageGallery[y].url).name)
                            }
                        }
                        imageGallery.refs = imageGalleryUrls
                        }
                    }
                    else
                    {
                        let publicimageGallery = {}
                        publicimageGallery.id = this.products[i].uniqueID
                        this.publicPreparedUrls.push(publicimageGallery)
                        let publicUrls = [];
                        for(let y=0;y<this.products[i].publicPreparedImages.length;y++)
                        {
                            let url = this.products[i].publicPreparedImages[y]
                            publicUrls.push(url)
                        }
                        publicimageGallery.refs = publicUrls
                    }
                }

                this.publicLinks.push(...this.urls)
                this.publicLinks.push(...this.publicPreparedUrls)

                if(this.urlRefs.length > 0)
                {
                    let dividedNumber = this.urlRefs.length / 25
                    if(dividedNumber >= 1 || (dividedNumber > 0 && dividedNumber < 1))
                    {
                        let slicedArray = [];
                        for(let i=0; i <= parseInt(dividedNumber); i++)
                        {
                            slicedArray.push(this.urlRefs.splice(0, 25))
                        }
                        if(slicedArray.length > 0)
                        {
                            for(let y=0; y < slicedArray.length; y++)
                            {
                                let result = await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/makePublic', {
                                    data: slicedArray[y]
                                })
                                this.publicLinks.push(...result.data.data)
                            }
                        }
    
                    }
                }

                setTimeout(() => {
                    this.componentInitLoad = false;
                    this.beforeLoad = false;
                }, 250);
            },
            async xmlCreate() {

                let xml = `<?xml version="1.0" encoding="utf-8"?> \n`
                    xml += `<offers xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1"> \n`

                for(let i=0; i<this.products.length; i++) {
                    
                    xml += `<o id="${i+1}" url="https://www.kompre.pl/produkt/${this.products[i].uniqueID}" price="${this.products[i].basePrice}" avail="${ this.products[i].active ? '1' : '99' }" stock="${this.products[i].quantity}" basket="${ this.products[i].active ? '1' : '0' }"> \n`
                    xml += `<cat> \n`
                    xml += `<![CDATA[${this.products[i].category}]]> \n`
                    xml += `</cat> \n`
                    xml += `<name> \n`
                    xml += `<![CDATA[${this.products[i].name}]]> \n`
                    xml += `</name> \n`
                    xml += `<imgs> \n`

                    for(let y=0; y<this.publicLinks.length; y++)
                    {
                        if(this.products[i].uniqueID === this.publicLinks[y].id)
                        {
                            for(let x=0; x<this.publicLinks[y].refs.length; x++)
                            {
                                if(x === 0)
                                {
                                    xml += `<main url="${this.publicLinks[y].refs[x]}"/> \n`
                                } else if(this.publicLinks[y].refs.length > 1)
                                {
                                    xml += `<i url="${this.publicLinks[y].refs[x]}"/> \n`
                                }
                            }
                            break;
                        }
                    }

                    xml += `</imgs> \n`
                    xml += `<desc> \n`
                    xml += `<![CDATA[`

                    // if(this.products[i].descSections.length > 0) {
                    //     for(let f=0; f<this.products[i].descSections.length; f++) {
                    //         xml += `<h2>${this.products[i].descSections[f].header}</h2><br><br>${this.products[i].descSections[f].text}<br><br> \n`
                    //     }
                    // } else {
                    //     xml += '<h1>testowy produkt</h1>'
                    // }

                    xml += '<h1>-</h1>'

                    xml += `]]> \n`
                    xml += `</desc> \n`
                    xml += `<attrs> \n`

                    xml += `<a name="Producent"> \n`
                    xml += `<![CDATA[${this.products[i].manufacturer}]]> \n`
                    xml += `</a> \n`

                    xml += `<a name="Kod producenta"> \n`
                    xml += `<![CDATA[${this.products[i].model}]]> \n`
                    xml += `</a> \n`

                    if(this.products[i].ean === "") {
                        xml += `<a name="EAN"> \n`
                        xml += `<![CDATA[00000000]]> \n`
                        xml += `</a> \n`
                    } else {
                        xml += `<a name="EAN"> \n`
                        xml += `<![CDATA[${this.products[i].ean}]]> \n`
                        xml += `</a> \n`
                    }

                    if(this.products[i].sku === "") {
                        xml += `<a name="SKU"> \n`
                        xml += `<![CDATA[null]]> \n`
                        xml += `</a> \n`
                    } else {
                        xml += `<a name="SKU"> \n`
                        xml += `<![CDATA[${this.products[i].sku}]]> \n`
                        xml += `</a> \n`
                    }

                    if(this.products[i].specs.additionalInfo.value === "") {
                    } else {
                        xml += `<a name="Informacje dodatkowe"> \n`
                        xml += `<![CDATA[${this.products[i].specs.additionalInfo.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.allConnectionsOutside.value === "") {
                    } else {
                        xml += `<a name="Złącza zewnętrzne"> \n`
                        xml += `<![CDATA[${this.products[i].specs.allConnectionsOutside.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.angleAdjustment.value === "") {
                    } else {
                        xml += `<a name="Regulacja kąta nachylenia"> \n`
                        xml += `<![CDATA[${this.products[i].specs.angleAdjustment.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.backConnections.value === "") {
                    } else {
                        xml += `<a name="Złącza z tyłu"> \n`
                        xml += `<![CDATA[${this.products[i].specs.backConnections.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.backlight.value === "") {
                    } else {
                        xml += `<a name="Podświetlenie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.backlight.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.battery.value === "") {
                    } else {
                        xml += `<a name="Bateria"> \n`
                        xml += `<![CDATA[${this.products[i].specs.battery.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.brightness.value === "") {
                    } else {
                        xml += `<a name="Jasność"> \n`
                        xml += `<![CDATA[${this.products[i].specs.brightness.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.camera.value === "") {
                    } else {
                        xml += `<a name="Kamera"> \n`
                        xml += `<![CDATA[${this.products[i].specs.camera.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.cameraBack.value === "") {
                    } else {
                        xml += `<a name="Aparat (Tył)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.cameraBack.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.cameraFront.value === "") {
                    } else {
                        xml += `<a name="Aparat (Przód)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.cameraFront.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.capacity.value === "") {
                    } else {
                        xml += `<a name="Pojemność"> \n`
                        xml += `<![CDATA[${this.products[i].specs.capacity.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.capacityOfBattery.value === "") {
                    } else {
                        xml += `<a name="Pojemność akumulatora"> \n`
                        xml += `<![CDATA[${this.products[i].specs.capacityOfBattery.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.casing.value === "") {
                    } else {
                        xml += `<a name="Obudowa"> \n`
                        xml += `<![CDATA[${this.products[i].specs.casing.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.casingCondition.value === "") {
                    } else {
                        xml += `<a name="Stan obudowy"> \n`
                        xml += `<![CDATA[${this.products[i].specs.casingCondition.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.coaType.value === "") {
                    } else {
                        xml += `<a name="Typ licencji"> \n`
                        xml += `<![CDATA[${this.products[i].specs.coaType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.colorType.value === "") {
                    } else {
                        xml += `<a name="Kolor"> \n`
                        xml += `<![CDATA[${this.products[i].specs.colorType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.contrast.value === "") {
                    } else {
                        xml += `<a name="Kontrast"> \n`
                        xml += `<![CDATA[${this.products[i].specs.contrast.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.coreNum.value === "") {
                    } else {
                        xml += `<a name="Ilość rdzeni"> \n`
                        xml += `<![CDATA[${this.products[i].specs.coreNum.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.cpuSpeed.value === "") {
                    } else {
                        xml += `<a name="Taktowanie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.cpuSpeed.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.dataTransmision.value === "") {
                    } else {
                        xml += `<a name="Transmisja danych"> \n`
                        xml += `<![CDATA[${this.products[i].specs.dataTransmision.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.displayResolution.value === "") {
                    } else {
                        xml += `<a name="Rozdzielczość ekranu"> \n`
                        xml += `<![CDATA[${this.products[i].specs.displayResolution.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.displaySize.value === "") {
                    } else {
                        xml += `<a name="Przekątna ekranu"> \n`
                        xml += `<![CDATA[${this.products[i].specs.displaySize.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.displayTouchable.value === "") {
                    } else {
                        xml += `<a name="Ekran dotykowy"> \n`
                        xml += `<![CDATA[${this.products[i].specs.displayTouchable.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.displayType.value === "") {
                    } else {
                        xml += `<a name="Powłoka matrycy"> \n`
                        xml += `<![CDATA[${this.products[i].specs.displayType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.dvd.value === "") {
                    } else {
                        xml += `<a name="Napęd"> \n`
                        xml += `<![CDATA[${this.products[i].specs.dvd.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.frontConnections.value === "") {
                    } else {
                        xml += `<a name="Złącza z przodu"> \n`
                        xml += `<![CDATA[${this.products[i].specs.frontConnections.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.functions.value === "") {
                    } else {
                        xml += `<a name="Funkcje"> \n`
                        xml += `<![CDATA[${this.products[i].specs.functions.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.graphics.value === "") {
                    } else {
                        xml += `<a name="Model karty graficznej"> \n`
                        xml += `<![CDATA[${this.products[i].specs.graphics.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.graphicsType.value === "") {
                    } else {
                        xml += `<a name="Rodzaj karty graficznej"> \n`
                        xml += `<![CDATA[${this.products[i].specs.graphicsType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.hardDrive.value === "") {
                    } else {
                        xml += `<a name="Dysk"> \n`
                        xml += `<![CDATA[${this.products[i].specs.hardDrive.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.hasSpeaker.value === "") {
                    } else {
                        xml += `<a name="Wbudowany głośnik"> \n`
                        xml += `<![CDATA[${this.products[i].specs.hasSpeaker.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.heightAdjustment.value === "") {
                    } else {
                        xml += `<a name="Regulacja wysokości"> \n`
                        xml += `<![CDATA[${this.products[i].specs.heightAdjustment.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.inPack.value === "") {
                    } else {
                        xml += `<a name="W zestawie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.inPack.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.insideConnections.value === "") {
                    } else {
                        xml += `<a name="Złącza wewnętrzne"> \n`
                        xml += `<![CDATA[${this.products[i].specs.insideConnections.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.installedOS.value === "") {
                    } else {
                        xml += `<a name="Zainstalowany system"> \n`
                        xml += `<![CDATA[${this.products[i].specs.installedOS.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.intensity.value === "") {
                    } else {
                        xml += `<a name="Natężenie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.intensity.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.interfaceType.value === "") {
                    } else {
                        xml += `<a name="Interfejs"> \n`
                        xml += `<![CDATA[${this.products[i].specs.interfaceType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemCondition.value === "") {
                    } else {
                        xml += `<a name="Kondycja"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemCondition.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemDepth.value === "") {
                    } else {
                        xml += `<a name="Głębokość (cm)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemDepth.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemHeight.value === "") {
                    } else {
                        xml += `<a name="Wysokość (cm)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemHeight.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemKind.value === "") {
                    } else {
                        xml += `<a name="Rodzaj"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemKind.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemLength.value === "") {
                    } else {
                        xml += `<a name="Długość (cm)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemLength.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemStandard.value === "") {
                    } else {
                        xml += `<a name="Standard"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemStandard.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.itemWidth.value === "") {
                    } else {
                        xml += `<a name="Szerokość (cm)"> \n`
                        xml += `<![CDATA[${this.products[i].specs.itemWidth.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.keyboard.value === "") {
                    } else {
                        xml += `<a name="Klawiatura"> \n`
                        xml += `<![CDATA[${this.products[i].specs.keyboard.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.mobileProductProcessor.value === "") {
                    } else {
                        xml += `<a name="Procesor"> \n`
                        xml += `<![CDATA[${this.products[i].specs.mobileProductProcessor.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.mobileScreen.value === "") {
                    } else {
                        xml += `<a name="Rodzaj wyświetlacza"> \n`
                        xml += `<![CDATA[${this.products[i].specs.mobileScreen.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.motherboard.value === "") {
                    } else {
                        xml += `<a name="Płyta główna"> \n`
                        xml += `<![CDATA[${this.products[i].specs.motherboard.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.navigation.value === "") {
                    } else {
                        xml += `<a name="Nawigacja"> \n`
                        xml += `<![CDATA[${this.products[i].specs.navigation.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.networkStandard.value === "") {
                    } else {
                        xml += `<a name="Transmisja danych"> \n`
                        xml += `<![CDATA[${this.products[i].specs.networkStandard.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.networkWireless.value === "") {
                    } else {
                        xml += `<a name="Karta Sieciowa"> \n`
                        xml += `<![CDATA[${this.products[i].specs.networkWireless.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.os.value === "") {
                    } else {
                        xml += `<a name="Licencja"> \n`
                        xml += `<![CDATA[${this.products[i].specs.os.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.pivot.value === "") {
                    } else {
                        xml += `<a name="Pivot"> \n`
                        xml += `<![CDATA[${this.products[i].specs.pivot.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.power.value === "") {
                    } else {
                        xml += `<a name="Moc"> \n`
                        xml += `<![CDATA[${this.products[i].specs.power.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.powerSupply.value === "") {
                    } else {
                        xml += `<a name="Ładowarka"> \n`
                        xml += `<![CDATA[${this.products[i].specs.powerSupply.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.processor.value === "") {
                    } else {
                        xml += `<a name="Procesor"> \n`
                        xml += `<![CDATA[${this.products[i].specs.processor.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.processorType.value === "") {
                    } else {
                        xml += `<a name="Seria procesora"> \n`
                        xml += `<![CDATA[${this.products[i].specs.processorType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.productMemory.value === "") {
                    } else {
                        xml += `<a name="Wbudowana pamięć"> \n`
                        xml += `<![CDATA[${this.products[i].specs.productMemory.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.productType.value === "") {
                    } else {
                        xml += `<a name="Typ"> \n`
                        xml += `<![CDATA[${this.products[i].specs.productType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.ram.value === "") {
                    } else {
                        xml += `<a name="Ilość pamięci RAM"> \n`
                        xml += `<![CDATA[${this.products[i].specs.ram.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.ramType.value === "") {
                    } else {
                        xml += `<a name="Typ pamięci RAM"> \n`
                        xml += `<![CDATA[${this.products[i].specs.ramType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.screenCondition.value === "") {
                    } else {
                        xml += `<a name="Stan ekranu"> \n`
                        xml += `<![CDATA[${this.products[i].specs.screenCondition.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.screenType.value === "") {
                    } else {
                        xml += `<a name="Typ matrycy"> \n`
                        xml += `<![CDATA[${this.products[i].specs.screenType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.secondHardDrive.value === "") {
                    } else {
                        xml += `<a name="Dodatkowy dysk"> \n`
                        xml += `<![CDATA[${this.products[i].specs.secondHardDrive.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.sensors.value === "") {
                    } else {
                        xml += `<a name="Czujniki"> \n`
                        xml += `<![CDATA[${this.products[i].specs.sensors.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.sideConnections.value === "") {
                    } else {
                        xml += `<a name="Złącza z boku"> \n`
                        xml += `<![CDATA[${this.products[i].specs.sideConnections.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.slotOfMemoryCard.value === "") {
                    } else {
                        xml += `<a name="Slot karty pamięci"> \n`
                        xml += `<![CDATA[${this.products[i].specs.slotOfMemoryCard.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.socket.value === "") {
                    } else {
                        xml += `<a name="Gniazdo procesora"> \n`
                        xml += `<![CDATA[${this.products[i].specs.socket.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.sound.value === "") {
                    } else {
                        xml += `<a name="Karta dźwiękowa"> \n`
                        xml += `<![CDATA[${this.products[i].specs.sound.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.structureType.value === "") {
                    } else {
                        xml += `<a name="Układ"> \n`
                        xml += `<![CDATA[${this.products[i].specs.structureType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.tensity.value === "") {
                    } else {
                        xml += `<a name="Napięcie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.tensity.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.typeOfHardrive.value === "") {
                    } else {
                        xml += `<a name="Typ dysku"> \n`
                        xml += `<![CDATA[${this.products[i].specs.typeOfHardrive.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.usageType.value === "") {
                    } else {
                        xml += `<a name="Przeznaczenie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.usageType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.viewAngle.value === "") {
                    } else {
                        xml += `<a name="Kąt widzenia"> \n`
                        xml += `<![CDATA[${this.products[i].specs.viewAngle.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.warrantyType.value === "") {
                    } else {
                        xml += `<a name="Gwarancja"> \n`
                        xml += `<![CDATA[${this.products[i].specs.warrantyType.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.weight.value === "") {
                    } else {
                        xml += `<a name="Waga"> \n`
                        xml += `<![CDATA[${this.products[i].specs.weight.value}]]> \n`
                        xml += `</a> \n`
                    }
                    if(this.products[i].specs.withFoot.value === "") {
                    } else {
                        xml += `<a name="Stopa w komplecie"> \n`
                        xml += `<![CDATA[${this.products[i].specs.withFoot.value}]]> \n`
                        xml += `</a> \n`
                    }

                    xml += `</attrs> \n`
                    xml += `</o> \n`
                    xml += `\n`

                }

                xml += `</offers>`

                const file = new File([xml], `kompre.xml`, {
                type: 'text/plain',
                })

                const link = document.createElement('a')
                const url = URL.createObjectURL(file)

                link.href = url
                link.download = file.name
                document.body.appendChild(link)
                link.click()

                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)


                setTimeout(() => {
                    this.clickedButton = true;
                    this.isDownloaded = true;
                }, 100);
            },
        },
        async created() {
            window.scrollTo(0,0);
            await this.getItems();
            await this.xmlCreate()
        },
        components: {
            navbar, trust, ftr
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
    }

    .noDataSearch
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4.768rem;
        margin-bottom: 4.768rem;
        user-select: none;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;

        &__head
        {
            font-weight: 500;
        }
    }

    .xml
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;

        &__content
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;

            &__results
            {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &__text
                {
                    margin-top: 1rem;
                    user-select: none;
                    color: $gray;
                }

                &__row
                {
                    display: flex;
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    justify-content: space-evenly;
                    width: 100%;
                }

            }

            &__texts
            {
                width: 100%;
                margin-bottom: 1rem;
                h1 {
                    text-align: left;
                    user-select: none;
                    margin: 0;
                    padding: 0;
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 500;
                    font-size: 1.25rem;
                }
                h3
                {
                    text-align: left;
                    font-weight: 400;
                    padding: 0;
                    font-size: 1rem;
                    margin: 0;
                }
            }

            &__input
            {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
                box-sizing: border-box;

                &__getQuery
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    input
                    {
                        width: 35%;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        font-weight: 400;
                        text-indent: 1px;
                        text-overflow: '';
                        font-size: 1rem;
                        z-index: 2;
                        border: none;
                        background: none;
                        font-size: 1.25rem;
                        outline: none;
                        color: $gray;
                        transition: all 0.4s ease-in-out;
                        padding-bottom: 0.64rem;
                        padding-top: 0.64rem;
                        padding-left: 2.441rem;
                        padding-right: 2.441rem;
                        border-bottom: 1px solid #dddddd;
                        opacity: 0.7rem;
                        transition: all 0.4s ease-in;
                    }

                    input::placeholder
                    {
                        color: $gray;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    input[type="text"]:focus
                    {
                        
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        padding-left: 3.052rem;
                        padding-right: 3.052rem;
                    }

                    button
                    {
                        margin-top: 1rem;
                        border: 2px solid $red;
                        color: $red;
                        font-size: 1rem;
                        background: none;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-top: 0.64rem;
                        padding-bottom: 0.64rem;
                        border-radius: 5px;
                        transition: all 0.4s ease-in-out;
                    }
                    button:disabled
                    {
                        pointer-events: none;
                        opacity: 0.5;
                    }

                    button:hover
                    {
                        cursor: pointer;
                        border: 2px solid $redHover;
                        color: $redHover;
                        border-radius: 10px;
                        transform: translateY(-1px);
                        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
                    }



                    &__icon
                    {
                        position: relative;
                        left: 25px;
                        z-index: 1;
                        color: $black;
                    }

                }

 
            }
        }
    }




    @media (max-width: 1024px)
    {
        .xml__content__results__row
        {
            flex-direction: column;
            width: 80%;
        }


    }
    @media (max-width: 769px) {
    .xml{
        margin-top: 4.768em;
    }
}



</style>

<style lang="scss" scoped>
@import "../styles/variables";
.checkbox {
  width: 15px;
  height: 15px;
  position: relative;
  label {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      top: 3px;
      left: 3px;
      border: 2px solid $red;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      opacity: 0.2;
    }
  }
  label:hover{
      background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }    
}

.modal{
    width: 22.737rem;
    height: 100vh;
    // padding: 1.563rem;
    background: white;
    animation: fadeInRight 0.5s ease-in-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &__close{   
        z-index: 1;
        padding-top: 1.563rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        display: flex;
        height: 4.126rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #ececec;
        span{
            font-size: 1rem;
            margin: 0;
            padding: 0;
            margin-right: auto;
            height: 1.563rem;
        }
        i{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
        color: black;
        transition: all 0.2s ease-in-out;
        }
        i::before{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1rem;
            height: 1rem;
        }
        i:hover{
            color: $red;
            cursor: pointer;
        }
    }
    &__loading
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: calc(100% - 4.126rem - 4.563rem);
    };

    &__filters{
        display: flex;
        flex-direction: column;
        height: calc(100% - 4.126rem - 4.563rem);
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__category{
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &__name{
                display: flex;
                user-select: none;
                flex-direction: column;
                margin-bottom: 0.21rem;
                h4{
                    padding: 0;
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 500;
                    line-height: 1.25rem;
                }
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #6e6e6e;
                    cursor: pointer;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    transition: all 0.2s ease-in-out;
                }
                span:hover{
                    user-select: none;
                    color: $red;
                }
            }
                &__noData
                {
                    user-select: none;
                    font-size: 0.8rem;
                    opacity: 0.4
                }

                &__filter{
                display: flex;
                align-items: center;
                margin-top: 0.21rem;
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    margin-left: 0.41rem;
                    padding-top: 1px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                    span{
                        user-select: none;
                        font-size: 0.64rem;
                        margin: 0;
                        color: #6e6e6e;
                    }
                }
                span:hover{
                    user-select: none;
                    color: $red;
                    span{
                        color: $red;
                    }
                }
            }
            &__priceBox{
                display: flex;
                align-items: center;
                height: 1.953rem;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-top: 0.41rem;
                &__price{
                    display: flex;
                    align-items: center;
                    padding-left: 0.41rem;
                    padding-right: 0.41rem;
                    // border: 1px solid #333;
                    border-radius: 5px;
                    height: 100%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
                    cursor: text;
                    transition: all 0.3s ease-in-out;
                    span{
                        font-size: 0.8rem;
                        font-weight: 400;
                    }
                    input{
                        width: 3.815rem;
                        background: none;
                        // height: calc(100%-2px);
                        height: 100%;
                        border: none !important;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        font-family: 'Poppins', sans-serif !important;
                    }
                    input:focus{
                    outline-style: none;
                    box-shadow: none;
                    border-color: none;
                    background-color: none;
                    color: none;

                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                
                }
                &__price:hover{
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
                }
                span{
                    margin-left: 0.41rem;
                    margin-right: 0.41rem;
                }
            }
            &__select{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%) !important;
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5) !important;
                border-radius: 5px;
                margin-top: 0.21rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                select{
                    padding: 0;
                    margin: 0;
                    height: 1.953rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    font-family: 'Poppins', sans-serif !important;
                    font-size: 0.8rem;
                    padding: 0.21rem;
                    border: none;
                    outline-style: none;
                    box-shadow: none;
                    border-color: none;
                    background-color: none;
                    background: #ffffff;
                    color: none;
                    color: black;
                    border-radius: 5px;
                }
            }
            &__select:hover{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
            }
        }
        &__category:last-child{
            margin: 0;
        }
        
    }
    &__save{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999 !important;
        height: 4.563rem;
        width: 100%;
        background: pink;
        padding-top: 1rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-bottom: 1rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid #ececec;
        background: #f7f7f7;
        button{
            cursor: pointer;
            padding: 0.64rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border: none;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: 400;
            border: 1px solid #d1d1d1;
        }

        button:hover
        {
            opacity: 0.6;
        }
    }
}
.hide{
    display: none;
}

.saveButton{
    margin-left: 0.64rem;
}

.backdrop {
    z-index: 1001;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

    .emptyState
        {
            width: 100vw;
            height: 60vh;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            user-select: none;
    
            &__header
            {
                font-size: 1.25rem;
                font-weight: 500;
                opacity: 0.8;
            }

            &__subtext
            {
                font-size: 0.85rem;
                font-weight: 500;
                opacity: 0.6;
            }
            
            &__button
            {
                font-size: 1.25rem;
                font-weight: 500;
                opacity: 0.6;
            }
            
            &__image
            {
                opacity: 0.65;
    
                img
                {
                    width: 65%;
                    height: auto;
                }
            }
            &__imageSecond
            {
                opacity: 0.65;
                img
                {
                    width: 45%;
                    height: auto;
                }
            }
        }

@keyframes fadeInRight {
  from {
    opacity: 1;
    transform: translateX(650px);
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 500px) {
    
    .emptyState{
        height: 50vh;
    }

    .modal{
        width: 100vw;
        height: 100%;
        &__filters{
            &__category{
                &__name{
                    span{
                        margin-top: 0.21rem;
                        margin-bottom: 0.21rem;
                    }
                }
            }
        }
        }
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 5px solid $red; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
